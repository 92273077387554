import React, { StrictMode } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles/App.css";
import "./styles/fonts.css";
import './utils/ga.js';
import ScrollToTop from "./components/ScrollToTop";
import { ChakraProvider } from "@chakra-ui/react";

//Routings
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import OurStory from "./pages/OurStory";
import OurWater from "./pages/OurWater";
import Sustainability from "./pages/SustainabilityAndCommunity";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
import FAQ from "./pages/FAQ";

function App() {
  return (
    // routings
    <ChakraProvider>
      <StrictMode>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/our-water" element={<OurWater />} />
            <Route
              path="/our-sustainability-and-community-plan"
              element={<Sustainability />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
        </BrowserRouter>
      </StrictMode>
    </ChakraProvider>
  );
}

export default App;