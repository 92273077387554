import "../styles/Careers.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import backgroundImage from "../images/toile_de_jouy_pattern_1.png";
import { log, error } from '../utils/log';

//chakra ui
import {
  ChakraProvider,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Textarea,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

function Careers() {
  //positions firestore
  const [positions, setPositions] = useState([]);

  /**
   * Fetches news data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/positions`);
        setPositions(response.data);
        log(response.data);
      } catch (error) {
        error("Error fetching positions:", error);
      }
    };

    fetchPositions();
  }, []);

  /**
   * @param {function} handleSubmit - function to handle form submission.
   * @param {function} register - function to register form inputs.
   * @param {object} formState - state object containing form state properties.
   * @param {object} formState.errors - check for form validation errors.
   * @param {function} reset - function to reset the form after submission.
   */
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const toast = useToast();

  //function to submit the form with toast message
  async function onSubmit(values) {
    try {
      /*
        check if the cover letter is not empty.
        If the cover letter is empty, the value sent to the backend will be NIL
        If cover letter is not empty, the value written is sent to the backend
      */
      var tempCoverLetter;

      if (!values.coverLetter || values.coverLetter.trim() === "") {
        log("Cover letter is empty");
        tempCoverLetter = "NIL";
      } else {
        tempCoverLetter = values.coverLetter;
        log("Cover letter is not empty");
      }

      //invoke server url to trigger email API
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/send-job-application-email`,
        {
          applicantEmail: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          position: values.position,
          coverLetter: tempCoverLetter,
          resume: values.resume[0],
        },

        //for resume attachment
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      /*
      * check for response status sent by server
        status 2XX = success
        status 4XX = client error
        status 5XX = server error
      */
      if (response.status === 200) {
        toast({
          title: "Application sent successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        reset();
      } else {
        toast({
          title: "Error sending application Form. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred. Please try again.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <ChakraProvider>
      {/* Page title */}
      <Helmet>
        <title>Careers</title>
      </Helmet>

      <NavBar />

      <div className="container-fluid background-container">
        {/* background image */}
        <div
          className="background-overlay"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>

        {/* page content */}
        <div className="container content px-5">
          {/* header texts */}
          <div className="row text-center mb-3">
            <h1 className="header">We Are Hiring</h1>
            <p className="subText">
              Be a part of our dynamic team dedicated to delivering premium
              mineral water and driving eco-friendly innovation.
            </p>
          </div>

          <div className="row text-start my-4">
            <p className="subText">
              These are the available job openings we have, please click on each
              role to view the job description:
            </p>
            <ul className="subText">
              {positions.map((role, index) => (
                <li key={role.id || index}>
                  <a href={role.jd} target="_blank" rel="noreferrer">
                    {role.role}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row my-5">
              {/* first name */}
              <div className="col-md-5">
                <FormControl isInvalid={errors.firstName}>
                  <FormLabel
                    htmlFor="firstName"
                    className="header"
                    fontSize="2xl"
                    color="#213954"
                    sx={{
                      fontSize: ["20px", "20px", "24px", "24px"],
                    }}
                  >
                    <b>
                      First Name <span style={{ color: "red" }}>*</span>
                    </b>
                  </FormLabel>
                  <Input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    variant="flushed"
                    className="subText"
                    fontSize="md"
                    borderBottomWidth="1px"
                    borderBottomColor="#213954"
                    sx={{
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                    //input validation
                    {...register("firstName", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.firstName && errors.firstName.message}
                  </FormErrorMessage>
                </FormControl>
              </div>

              <div className="col-md-2"></div>

              {/* last name */}
              <div className="col-md-5 mt-4 mt-md-1">
                <FormControl isInvalid={errors.lastName}>
                  <FormLabel
                    htmlFor="lastName"
                    className="header"
                    fontSize="2xl"
                    color="#213954"
                    sx={{
                      fontSize: ["20px", "20px", "24px", "24px"],
                    }}
                  >
                    <b>Last Name <span style={{ color: "red" }}>*</span></b>
                  </FormLabel>
                  <Input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    variant="flushed"
                    className="subText"
                    fontSize="md"
                    borderBottomWidth="1px"
                    borderBottomColor="#213954"
                    sx={{
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                    //input validation
                    {...register("lastName", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.lastName && errors.lastName.message}
                  </FormErrorMessage>
                </FormControl>
              </div>
            </div>

            <div className="row my-5">
              {/* email */}
              <div className="col-md-5">
                <FormControl isInvalid={errors.email}>
                  <FormLabel
                    htmlFor="email"
                    className="header"
                    fontSize="2xl"
                    color="#213954"
                    sx={{
                      fontSize: ["20px", "20px", "24px", "24px"],
                    }}
                  >
                    <b>
                      Email Address <span style={{ color: "red" }}>*</span>
                    </b>
                  </FormLabel>
                  <Input
                    type="text"
                    id="email"
                    placeholder="Email Address"
                    variant="flushed"
                    className="subText"
                    fontSize="md"
                    borderBottomWidth="1px"
                    borderBottomColor="#213954"
                    sx={{
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                    //input validation
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address format",
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
              </div>

              <div className="col-md-2"></div>

              {/* phone */}
              <div className="col-md-5 mt-4 mt-md-1">
                <FormControl isInvalid={errors.phone}>
                  <FormLabel
                    htmlFor="phone"
                    className="header"
                    fontSize="2xl"
                    color="#213954"
                    sx={{
                      fontSize: ["20px", "20px", "24px", "24px"],
                    }}
                  >
                    <b>Phone No.</b>
                  </FormLabel>
                  <Input
                    type="tel"
                    id="phone"
                    placeholder="Phone No."
                    variant="flushed"
                    className="subText"
                    fontSize="md"
                    borderBottomWidth="1px"
                    borderBottomColor="#213954"
                    sx={{
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                    //input validation
                    {...register("phone", {
                      minLength: {
                        value: 10,
                        message: "Phone No. is invalid!",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone No. is invalid!",
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.phone && errors.phone.message}
                  </FormErrorMessage>
                </FormControl>
              </div>
            </div>

            {/* available openings */}
            <div className="row my-5">
              <div className="col-md-5 mt-2 mt-md-1">
                <FormControl isInvalid={errors.position}>
                  <FormLabel
                    htmlFor="position"
                    className="header"
                    fontSize="2xl"
                    color="#213954"
                    sx={{
                      fontSize: ["20px", "20px", "24px", "24px"],
                    }}
                  >
                    <b>
                      Role Interested <span style={{ color: "red" }}>*</span>
                    </b>
                  </FormLabel>
                  <Select
                    variant="flushed"
                    placeholder="Select position"
                    className="subText"
                    fontSize="md"
                    borderBottomWidth="1px"
                    borderBottomColor="#213954"
                    {...register("position", {
                      required: "This field is required",
                    })}
                  >
                    {positions.map((position) => (
                      <option key={position.role} value={position.role}>
                        {position.role}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.position && errors.position.message}
                  </FormErrorMessage>
                </FormControl>
              </div>
            </div>

            {/* cover letter */}
            <div className="row my-5">
              <FormControl isInvalid={errors.coverLetter}>
                <FormLabel
                  htmlFor="coverLetter"
                  className="header"
                  fontSize="2xl"
                  color="#213954"
                  sx={{
                    fontSize: ["20px", "20px", "24px", "24px"],
                  }}
                >
                  <b>Cover Letter</b>
                </FormLabel>
                <Textarea
                  placeholder="Your cover letter here"
                  size="sm"
                  id="coverLetter"
                  bg="white"
                  className="subText"
                  fontSize="md"
                  rows="8"
                  sx={{
                    fontSize: ["14px", "14px", "16px", "16px"],
                  }}
                  {...register("coverLetter")}
                />
              </FormControl>
            </div>

            {/* resume file insert */}
            <div className="row my-5">
              <FormControl isInvalid={errors.resume}>
                <FormLabel
                  htmlFor="resume"
                  className="header"
                  fontSize="2xl"
                  color="#213954"
                  sx={{
                    fontSize: ["20px", "20px", "24px", "24px"],
                  }}
                >
                  <b>
                    Resume <span style={{ color: "red" }}>*</span>
                  </b>
                </FormLabel>
                <input
                  className="form-control form-control-lg subText"
                  id="resume"
                  type="file"
                  fontSize="md"
                  //file validation, only PDF, DOC, and DOCX files are accepted
                  {...register("resume", {
                    required: "Please upload your resume",
                    validate: {
                      fileType: (value) =>
                        (value &&
                          value[0]?.type &&
                          [
                            "application/pdf",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ].includes(value[0].type)) ||
                        "Only PDF, DOC, and DOCX files are accepted",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.resume && errors.resume.message}
                </FormErrorMessage>
              </FormControl>
            </div>

            <Button
              bg="#CDAB63"
              color="#213954"
              isLoading={isSubmitting}
              type="submit"
              className="mb-5 subText"
              fontSize="md"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>

      <Footer />
    </ChakraProvider>
  );
}

export default Careers;