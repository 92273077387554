import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * responsible for scrolling the window to the top whenever the location changes in the application to ensure that the user starts viewing a new page from the top
 * component uses the useLocation hook from the react-router-dom library to get the current location of the application
 */
function ScrollToTop() {
  //useLocation hook returns an object that contains information about the current URL path, including the pathname property
  const { pathname } = useLocation(); 

  //useEffect callback function is responsible for scrolling the window to the top, and the dependency array contains only the pathname
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
/**
 * including pathname in the dependency array, the effect will be triggered whenever the pathname changes.
 * This ensures that the window is scrolled to the top whenever the user navigates to a new page.
 */

  return null;
}

export default ScrollToTop;