import React from "react";
import { Image } from "react-bootstrap";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  Heading,
  Text,
  Stack,
  LinkBox,
  LinkOverlay,
  Box,
} from "@chakra-ui/react";

const CardComponent = ({ img, productName, size }) => {
  return (
    <ChakraProvider>
      <LinkBox
        as="article"
        maxW={["100%", "sm"]} // Responsive width: full width on small screens, 'sm' on larger screens
        p={["0", "5"]} // Responsive padding: smaller padding on small screens
        borderWidth="1px"
        rounded="md"
        overflow="hidden"
        position="relative"
        style={{ border: "none", boxShadow: "none" }}
        _hover={{
          // reduce the opacity of the image when hovered
          ".overlay": {
            opacity: 0.75,
          },
        }}
      >
        <Box position="relative" overflow="hidden">
          {/* will appear originally */}
          <Box
            width={["75%", "75%", "100%", "100%"]} // Full width on small and medium screens, 75% on larger screens
            mx="auto" // Center the image horizontally
          >
            <Image
              src={img}
              alt={productName}
              width="100%" // Image takes full width of its container
              height="auto"
              fit="cover"
            />
          </Box>

          {/* this box will appear when hovering */}
          <Box
            className="overlay"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="gray.700"
            opacity="0"
            color="white"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
            flexDirection="column"
            transition="opacity 0.3s"
            p="4"
          >
            <Stack textAlign="center" mt="auto">
              <Text fontSize="xl" sx={{ fontFamily: "ZT-Kis" }}>
                Discover More
              </Text>
              <ArrowForwardIcon />
            </Stack>
          </Box>

        </Box>

        {/* description */}
        <Box className="py-4 px-5 px-md-0">
          <Stack>
            <Heading
              size="sm"
              sx={{ fontFamily: "Archivo, sans-serif", opacity: 0.75 }}
            >
              {productName}
            </Heading>
            <Text sx={{ fontFamily: "Archivo, sans-serif", opacity: 0.75 }}>
              {size}
            </Text>
          </Stack>
        </Box>

        <LinkOverlay href="/our-water" />
      </LinkBox>
    </ChakraProvider>
  );
};

export default CardComponent;