import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/Footer.css";
import logo from "../images/logo_light_saiyok_small.png";
import { FaLine } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { log, error } from "../utils/log";

const Footer = () => {
  const [para1, setPara1] = useState(null);
  const [para2, setPara2] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/misc/address`
        );
        log("Fetched footer address data:", response.data[0]);
        setPara1(response.data[0].paragraph1);
        setPara2(response.data[0].paragraph2);
      } catch (error) {
        error("Error fetching footer address data:", error);
      }
    };

    fetchAddress();
  }, []);

  const [companyName, setCompanyName] = useState(null);

  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/misc/companyName`
        );
        log("Fetched footer address data:", response.data[0]);
        setCompanyName(response.data[0].field);
      } catch (error) {
        error("Error fetching footer address data:", error);
      }
    };

    fetchCompanyName();
  }, []);

  return (
    <div
      className="container-fluid px-5"
      style={{ backgroundColor: "#213954", color: "white" }}
    >
      <div className="row justify-content-center pt-5 pb-1">
        {/* Contact section */}
        <div className="col-sm-2 py-sm-1 pb-2">
          <h5 className="footer">Contact</h5>
          <p className="footer">
            <Link to="/faq">FAQ</Link>
          </p>
          <p className="footer">
            <Link to="/careers">Careers</Link>
          </p>
        </div>

        {/* Social media section*/}
        <div className="col-sm-2 py-sm-1 py-2">
          <h5 className="footer">Get Involved</h5>
          <div className="d-flex">
            <a
              href="https://line.me/R/ti/p/@saiyoksprings"
              target="_blank"
              rel="noreferrer"
            >
              <FaLine style={{ fontSize: "35px" }} />
            </a>

            <a
              href="https://www.instagram.com/saiyoksprings"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagramSquare style={{ fontSize: "35px" }} />
            </a>
          </div>
        </div>

        {/* Blog*/}
        <div className="col-sm-1 py-sm-1 py-2s pt-4  pt-mb-0">
          <h5 className="footer">Blog</h5>
          <p className="footer">
            <a href="https://blog.radach.com/" target="_blank" rel="noreferrer">
              Our Blog Post
            </a>
          </p>
        </div>

        {/* Vertical line section, will disappear when the screen size is smaller than 576px */}
        <div className="col-sm-1 d-none d-sm-flex justify-content-sm-end">
          <div className="d-flex" style={{ height: "200px" }}>
            <div className="vr"></div>
          </div>
        </div>

        {/* Address and mail section */}
        <div className="col-sm-3 py-sm-1 pt-4">
          <p className="footer">
            <b>{companyName}</b> <br />
            {para1}
            <br />
            {para2} <br />
          </p>
          <br />
          <p className="footer">
            <a href="mailto:sales@radach.com" target="_blank" rel="noreferrer">
              sales@radach.com
            </a>
          </p>
        </div>

        {/* Logo section */}
        {/* Logo will disappear when the screen gets smaller */}
        <div className="col-sm-3 col-md-3 col-lg-1 py-1 d-none d-sm-block d-md-flex justify-content-md-end">
          <img id="footerImg" src={logo} alt="Sai Yok Springs Logo" />
        </div>
      </div>

      <hr className="mx-5"></hr>

      <section className="text-center pt-2 pb-2" id="section">
        <p className="custom">©2024 {companyName}</p>
      </section>
    </div>
  );
};

export default Footer;
