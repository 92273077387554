import React from "react";
import {
  ChakraProvider,
  Image,
  Text,
  Stack,
  Heading,
  LinkBox,
  LinkOverlay,
  Box,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const NewsComponent = ({ description, image, link, title }) => {

  return (
    <ChakraProvider>
      <motion.div className="my-4" whileHover={{ scale: 0.9 }}>
        {/* LinkBox element is being used as a wrapper around an article link */}
        <LinkBox as="article" href={link} target="_blank" rel="noreferrer">
          <Box
          // the first value "300px" corresponds to the maximum width when the screen size is small, 
          //the second value "400px" corresponds to the maximum width for medium-sized screens
          // the last two values "550px" correspond to the maximum width for larger screens.
            maxW={["300px", "400px", "550px", "550px"]}
            h={["550px", "550px", "600px", "650px"]}
            w="full"
            bg={useColorModeValue("#213954")}
            boxShadow={"2xl"}
            p={6}
            overflow={"hidden"}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box
              bg={"gray.100"}
              h={["250px", "250px", "400px", "400px"]}
              mt={-6}
              mx={-6}
              mb={6}
              pos={"relative"}
            >
              <Image
                src={image}
                alt={title}
                objectFit="cover"
                w="100%"
                h="100%"
              />
            </Box>

            <Stack flex="1" justify="space-between">
              <Box>
                <Heading
                  color={useColorModeValue("white")}
                  fontSize={["md", "xl"]}
                  fontFamily={"ZT-kis"}
                  textAlign="justify"
                  className="mb-4"
                >
                  {title}
                </Heading>

                <Text
                  color={"white"}
                  fontSize={"xs"}
                  fontFamily={"Archivo"}
                  textAlign="justify"
                  noOfLines={4}
                >
                  {description}
                </Text>
              </Box>
              
              <Button
                size="sm"
                variant="outline"
                mt={2}
                onClick={() => window.open(link, "_blank")}
                alignSelf="start"
                color="#CDAB63"
                borderColor="#CDAB63"
              >
                Read More
              </Button>
              
            </Stack>
          </Box>
          
          <LinkOverlay href={link} target="_blank" rel="noreferrer" />
        </LinkBox>
      </motion.div>
    </ChakraProvider>
  );
};

export default NewsComponent;