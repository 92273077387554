import "../styles/OurWater.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  ChakraProvider,
  Heading,
  Text,
  Box,
  Center,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { log, error } from "../utils/log";

//inserts values to the product component to render the data
const ProductComponent = ({
  type,
  bottleImg,
  description,
  calcium,
  magnesium,
  sodium,
  nitrate,
  ph,
  productName,
}) => {
  //products firestore
  const [uniqueSizes, setUniqueSizes] = useState([]);

  /**
   * Fetches products data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/products`);
        const fetchedProducts = response.data;

        // Extract sizes and filter unique values
        const sizes = fetchedProducts.map((product) => product.size);
        const uniqueSizesArray = [...new Set(sizes)];
        setUniqueSizes(uniqueSizesArray);
      } catch (error) {
        error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <ChakraProvider>
      <div className="row" style={{ marginBottom: "200px" }}>
        <div className="col-md-2"></div>

        {/* huge title*/}
        {/* vertical when screen is >= md, horizontal when screen is =< sm */}
        <div className="col-md-1 col-xs-3 ">
          <Heading
            as="h1"
            size="4xl"
            noOfLines={1}
            className="vertical-text"
            sx={{ textOrientation: "mixed", fontFamily: "'ZT-Kis'" }}
          >
            {type}
          </Heading>
        </div>

        {/* bottle image */}
        <div className="col-md-3 col-xs-3">
          {/* image animation */}
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
            style={{ display: "inline-block" }}
            className="d-flex justify-content-center justify-content-md-start mt-sm-3 mt-xs-3"
            // when the screen is >= md, the image will float to the start
            // when the screen is =< 576px (sm and xs), the image will float to the center
          >
            <Image className="mx-auto" id="productImg" src={bottleImg} />
          </motion.div>
        </div>

        {/* content */}
        <div className="col-md-6 align-self-center">
          <Heading
            size="sm"
            my="5"
            opacity="0.8"
            sx={{
              fontFamily: "'ZT-Kis'",
              fontSize: ["24px", "30px", "36px", "48px"],
            }}
          >
            {productName}
          </Heading>
          <br />
          <p className="subText" style={{ color: "grey" }}>
            Description
          </p>

          <Text
            className="subText"
            fontSize="md"
            sx={{
              textAlign: "justify",
              fontSize: ["14px", "14px", "16px", "16px"],
            }}
          >
            {description}
          </Text>
          <br />

          {/* grid to render lab results */}
          {/* the grid will have 2 columns when the screen is =< 576px and 3 columns when screen is >= 576px */}
          <SimpleGrid columns={[2, 2, 3]} spacing="30px">
            <Center h="80px">
              <Box>
                <Heading
                  size="sm"
                  color="gray.500"
                  mb="1"
                  sx={{
                    fontFamily: "'ZT-Kis'",
                    fontSize: ["14px", "14px", "18px", "18px"],
                  }}
                >
                  CALCIUM
                </Heading>
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  className="subText"
                  sx={{
                    fontSize: ["20px", "20px", "30px", "30px"],
                  }}
                >
                  {calcium}{" "}
                  <Box as="span" fontSize="md" fontWeight="normal">
                    mg/L
                  </Box>
                </Text>
              </Box>
            </Center>

            <Center h="80px">
              <Box>
                <Heading
                  size="sm"
                  color="gray.500"
                  mb="1"
                  sx={{
                    fontFamily: "'ZT-Kis'",
                    fontSize: ["14px", "14px", "18px", "18px"],
                  }}
                >
                  MAGNESIUM
                </Heading>
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  className="subText"
                  sx={{
                    fontSize: ["20px", "20px", "30px", "30px"],
                  }}
                >
                  {magnesium}{" "}
                  <Box as="span" fontSize="md" fontWeight="normal">
                    mg/L
                  </Box>
                </Text>
              </Box>
            </Center>

            <Center h="80px">
              <Box>
                <Heading
                  size="sm"
                  color="gray.500"
                  mb="1"
                  sx={{
                    fontFamily: "'ZT-Kis'",
                    fontSize: ["14px", "14px", "18px", "18px"],
                  }}
                >
                  SODIUM
                </Heading>
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  className="subText"
                  sx={{
                    fontSize: ["20px", "20px", "30px", "30px"],
                  }}
                >
                  {sodium}{" "}
                  <Box as="span" fontSize="md" fontWeight="normal">
                    mg/L
                  </Box>
                </Text>
              </Box>
            </Center>

            <Center h="80px">
              <Box>
                <Heading
                  size="sm"
                  color="gray.500"
                  mb="1"
                  sx={{
                    fontFamily: "'ZT-Kis'",
                    fontSize: ["14px", "14px", "18px", "18px"],
                  }}
                >
                  NITRATE
                </Heading>
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  className="subText"
                  sx={{
                    fontSize: ["20px", "20px", "30px", "30px"],
                  }}
                >
                  {nitrate}{" "}
                  <Box as="span" fontSize="md" fontWeight="normal">
                    mg/L
                  </Box>
                </Text>
              </Box>
            </Center>

            <Center h="80px">
              <Box>
                <Heading
                  size="sm"
                  color="gray.500"
                  mb="1"
                  sx={{
                    fontFamily: "'ZT-Kis'",
                    fontSize: ["14px", "14px", "18px", "18px"],
                  }}
                >
                  pH
                </Heading>
                <Text
                  fontSize="3xl"
                  fontWeight="bold"
                  className="subText"
                  sx={{
                    fontSize: ["20px", "20px", "30px", "30px"],
                  }}
                >
                  {ph}{" "}
                  <Box as="span" fontSize="md" fontWeight="normal">
                    at 25.2°C
                  </Box>
                </Text>
              </Box>
            </Center>
          </SimpleGrid>

          <br />
          <br />

          {/* sizes */}
          <SimpleGrid columns={[3]} spacing="5%" className="subText mx-3">
            {uniqueSizes.map((size, index) => (
              <Center key={index}>
                <Box
                  height="70px"
                  width="200px"
                  border="1px solid"
                  borderColor="gray.400"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                >
                  <Text
                    fontSize="md"
                    sx={{
                      fontSize: ["14px", "14px", "16px", "16px"],
                    }}
                  >
                    {size}
                  </Text>
                </Box>
              </Center>
            ))}
          </SimpleGrid>
        </div>
      </div>
    </ChakraProvider>
  );
};

export default ProductComponent;