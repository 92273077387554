import "../styles/OurWater.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  ChakraProvider,
  Heading,
  useMediaQuery,
  Text,
  Image,
} from "@chakra-ui/react";
import { log, error } from "../utils/log";

//components and images
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductComponent from "../components/ProductComponent";
import backgroundImage from "../images/toile_de_jouy_pattern_2.png";
import water from "../images/SaiyokSpring-084.jpg";

function OurWater() {
  const [isSmallScreen] = useMediaQuery("(max-width: 576px)");

  const [productDetails, setProductDetails] = useState([]);

  /**
   * Fetches products data from Firebase through server.js route
   */
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/productDetails`
        );
        setProductDetails(response.data);
      } catch (error) {
        error("Error fetching products details:", error);
      }
    };

    fetchProductDetails();
  }, []);

  /**
   * for firebase description content
   */
  const [paragraph1, setParagraph1] = useState([]);
  const [paragraph2, setParagraph2] = useState([]);
  const [title, setTitle] = useState([]);

  useEffect(() => {
    const fetchOurWaterData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/our-water/our-water`
        );
        log("Fetched our water data:", response.data);

        response.data.forEach((doc) => {
          if (doc.title) {
            setTitle(doc.title);
          }
          if (doc.paragraph1) {
            setParagraph1(doc.paragraph1);
          }
          if (doc.paragraph2) {
            setParagraph2(doc.paragraph2);
          }
        });
      } catch (error) {
        error("Error fetching data:", error);
      }
    };

    fetchOurWaterData();
  }, []);

  return (
    <ChakraProvider>
      <Helmet>
        <title>Our Water</title>
      </Helmet>

      <NavBar />

      <div className="container-fluid background-container">
        {/* background image */}
        <div
          className="background-overlay"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>

        {/* page content */}
        <div className="container content">
          {/*  Renders data through the ProductComponent */}
          {/* Also acts as a for loop for each data */}
          {productDetails.map((productDetail, index) => (
            <ProductComponent key={index} {...productDetail} />
          ))}

          {/* our water section */}
          <div className="row mb-5">
            <div className="col-md-2 d-flex justify-content-end z-md-3 ">
              <Heading
                size="7xl"
                noOfLines={isSmallScreen ? 3 : 2}
                className="vertical-text header"
                sx={{
                  textOrientation: "mixed",
                  opacity: "50%",
                  fontFamily: "'ZT-Kis'",
                  fontSize: ["48px", "48px", "72px", "72px"],
                }}
              >
                Natural Mineral Water
              </Heading>
            </div>

            <div className="col-md-4 d-flex justify-content-center">
              <Image
                src={water}
                sx={{
                  height: ["500px", "500px", "600px", "600px"],
                }}
              />
            </div>

            {/* content */}
            <div className="col-md-6 storyCol">
              <Heading
                className="header"
                fontSize="4xl"
                sx={{
                  fontFamily: "ZT-kis",
                  fontSize: ["24px", "25px", "30px", "34px"],
                }}
              >
                {title}
              </Heading>

              <Text
                  className="subText"
                  fontSize="md"
                  sx={{
                    textAlign: "justify",
                    fontSize: ["14px", "14px", "16px", "16px"],
                  }}
                >
                    {paragraph1}
                </Text>

                <Text
                  className="subText"
                  fontSize="md"
                  sx={{
                    textAlign: "justify",
                    fontSize: ["14px", "14px", "16px", "16px"],
                  }}
                >
                    {paragraph2}
                </Text>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </ChakraProvider>
  );
}

export default OurWater;
