import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import backgroundImage from "../images/toile_de_jouy_pattern_3.png";
import qr from "../images/qr.png";
import { log, error } from "../utils/log";

import {
  ChakraProvider,
  AspectRatio,
  Divider,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Heading,
  Textarea,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";

function Contact() {
  const [url, setUrl] = useState(null);
  
  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/contact/address`
        );
        log("Fetched contact data:", response.data[0]);
        setUrl(response.data[0].url); 
      } catch (error) {
        error("Error fetching contact data:", error);
      }
    };

    fetchContactData();
  }, []);

  const [para1, setPara1] = useState(null);
  const [para2, setPara2] = useState(null);
  
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/misc/address`
        );
        log("Fetched footer address data:", response.data[0]);
        setPara1(response.data[0].paragraph1);
        setPara2(response.data[0].paragraph2);
      } catch (error) {
        error("Error fetching footer address data:", error);
      }
    };

    fetchAddress();
  }, []);
  
  const [companyName, setCompanyName] = useState(null);
  
  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/misc/companyName`
        );
        log("Fetched footer address data:", response.data[0]);
        setCompanyName(response.data[0].field);
      } catch (error) {
        error("Error fetching footer address data:", error);
      }
    };

    fetchCompanyName();
  }, []);

  const [form, setForm] = useState(null);

  useEffect(() => {
    const fetchFormLinkData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/texts/contact/form`
        );
        log("Fetched data:", response.data[0]);
        setForm(response.data[0].link); // Assuming response.data contains the entire document data
      } catch (error) {
        error("Error fetching contact data:", error);
      }
    };

    fetchFormLinkData();
  }, []);

  /**
   * @param {function} handleSubmit - function to handle form submission.
   * @param {function} register - function to register form inputs.
   * @param {object} formState - state object containing form state properties.
   * @param {object} formState.errors - check for form validation errors.
   * @param {function} reset - function to reset the form after submission.
   */
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const toast = useToast();

  //function to submit the form with toast message
  async function onSubmit(values) {
    try {
      //invoke server url to trigger email API
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/send-enquiries-email`,
        {
          customerEmail: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          enquiry: values.enquiries,
        }
      );

      /*
       * check for response status sent by server
       * status 2XX = success
       * status 4XX = client error
       * status 5XX = server error
       */
      if (response.status === 200) {
        toast({
          title: "Enquiry sent successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        reset();
      } else {
        toast({
          title: "Error sending enquiry. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred. Please try again.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <ChakraProvider>
      {/* Page title */}
      <Helmet>
        <title>Contact Us</title>
      </Helmet>

      <NavBar />

      <div className="container-fluid background-container">
        {/* background image */}
        <div
          className="background-overlay"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        ></div>

        {/* page content */}
        <div className="container content">
          {/* header texts */}
          <div className="row text-center mb-3">
            <h1 className="header">Contact Us</h1>
          </div>

          {/* first section, form and contact details */}
          <div className="row mt-5 mb-5">
            <div className="col-md-1"></div>

            {/* contact details with email, faq, and LINEs*/}
            <div className="col-md-3 text-center text-md-start">
              {/* email */}
              <div className="row mb-md-5 mb-xs-5 mb-sm-3 mb-4">
                <h3 className="header">
                  <b>Email</b>
                </h3>
                <p className="subText">
                  <a href="mailto:example@example.com">
                    <u>sales@radach.com</u>
                  </a>
                </p>
              </div>

              {/* to faq page */}
              <div className="row mb-md-5 mb-xs-5 mb-sm-3 mb-4">
                <h3 className="header">
                  <b>FAQs</b>
                </h3>
                <p className="subText">
                  <u>
                    <Link to="/faq">Visit our FAQ page</Link>
                  </u>
                </p>
              </div>

              {/* LINE contact details */}
              <div className="row mb-md-5 mb-xs-5 mb-sm-5 mb-5">
                <h3 className="header">
                  <b>LINE</b>
                </h3>
                <p className="subText">
                  <u>
                    {" "}
                    <a
                      href="https://line.me/R/ti/p/@saiyoksprings"
                      target="_blank"
                      rel="noreferrer"
                    >
                      @saiyoksprings
                    </a>
                  </u>
                </p>
                <Image
                  className="d-xs-block mx-auto mx-md-0"
                  style={{ width: "auto" }}
                  sx={{
                    height: ["100px", "100px", "150px", "150px"],
                  }}
                  src={qr}
                />
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-7">
              <Text
                className="subText"
                fontSize="md"
                sx={{
                  textAlign: "justify",
                  fontSize: ["16px", "16px", "20px", "20px"],
                }}
              >
                To discuss pricing or explore partnering with Sai Yok Springs,
                please reach out to us through LINE.
              </Text>

              <br />

              <Text
                className="subText"
                fontSize="md"
                sx={{
                  textAlign: "justify",
                  fontSize: ["16px", "16px", "20px", "20px"],
                }}
              >
                Alternatively, you can complete our pre-sales form{" "}
                <u>
                  <a href={form} target="_blank" rel="noreferrer">
                    here
                  </a>
                </u>{" "}
                for a prompt response from our team
              </Text>

              <br />
              <Divider borderWidth="1px" borderColor="#213954" />
              <br />

              <Heading
                className="header"
                fontSize="2xl"
                sx={{
                  fontFamily: "ZT-kis",
                  fontSize: ["20px", "20px", "24px", "24px"],
                }}
              >
                Leave Us A Message
              </Heading>

              <Text
                className="subText"
                fontSize="md"
                sx={{
                  fontFamily: "Archivo",
                  textAlign: "justify",
                  fontSize: ["14px", "14px", "16px", "16px"],
                }}
              >
                Your message will be sent to our team, who will get back to you
                as soon as they can. Please be advised that weekends and public
                holidays might delay response time.
              </Text>

              <br />

              {/* form */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-5">
                  <FormControl isInvalid={errors.firstName}>
                    <FormLabel
                      htmlFor="firstName"
                      className="header"
                      fontSize="2xl"
                      color="#213954"
                      sx={{
                        fontSize: ["20px", "20px", "24px", "24px"],
                      }}
                    >
                      <b>
                        First Name <span style={{ color: "red" }}>*</span>
                      </b>
                    </FormLabel>
                    <Input
                      type="text"
                      id="firstName"
                      placeholder="First Name"
                      variant="flushed"
                      className="subText"
                      fontSize="md"
                      borderBottomWidth="1px"
                      borderBottomColor="#213954"
                      sx={{
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                      //input validation
                      {...register("firstName", {
                        required: "This field is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.firstName && errors.firstName.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>

                <div className="row mb-5">
                  <FormControl isInvalid={errors.lastName}>
                    <FormLabel
                      htmlFor="lastName"
                      className="header"
                      fontSize="2xl"
                      color="#213954"
                      sx={{
                        fontSize: ["20px", "20px", "24px", "24px"],
                      }}
                    >
                      <b>Last Name</b>
                    </FormLabel>
                    <Input
                      type="text"
                      id="lastName"
                      placeholder="Last Name"
                      variant="flushed"
                      className="subText"
                      fontSize="md"
                      borderBottomWidth="1px"
                      borderBottomColor="#213954"
                      sx={{
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                      {...register("lastName")}
                    />
                    <FormErrorMessage>
                      {errors.lastName && errors.lastName.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>

                <div className="row mb-5">
                  <FormControl isInvalid={errors.email}>
                    <FormLabel
                      htmlFor="email"
                      className="header"
                      fontSize="2xl"
                      color="#213954"
                      sx={{
                        fontSize: ["20px", "20px", "24px", "24px"],
                      }}
                    >
                      <b>
                        Email Address <span style={{ color: "red" }}>*</span>
                      </b>
                    </FormLabel>
                    <Input
                      type="text"
                      id="email"
                      placeholder="Email Address"
                      variant="flushed"
                      className="subText"
                      fontSize="md"
                      borderBottomWidth="1px"
                      borderBottomColor="#213954"
                      sx={{
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                      //input validation
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid email address format",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.email && errors.email.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>

                <div className="row mb-5">
                  <FormControl isInvalid={errors.enquiries}>
                    <FormLabel
                      htmlFor="enquiries"
                      className="header"
                      fontSize="2xl"
                      color="#213954"
                      sx={{
                        fontSize: ["20px", "20px", "24px", "24px"],
                      }}
                    >
                      <b>
                        Enquiry <span style={{ color: "red" }}>*</span>
                      </b>
                    </FormLabel>
                    <Textarea
                      placeholder="Please enter your enquiries here"
                      size="sm"
                      id="enquiries"
                      bg="white"
                      className="subText"
                      fontSize="md"
                      rows="8"
                      sx={{
                        fontSize: ["14px", "14px", "16px", "16px"],
                      }}
                      //input validation
                      {...register("enquiries", {
                        required: "This field is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.enquiries && errors.enquiries.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>

                <div className="row mb-5">
                  <Button
                    bg="#CDAB63"
                    color="#213954"
                    isLoading={isSubmitting}
                    type="submit"
                    className="subText"
                    fontSize="md"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>

          {/* second section, map */}
          <div className="row mb-5">
            <div className="col-md-12">
              <div>
                <Heading
                  className="header"
                  fontSize="2xl"
                  sx={{
                    fontFamily: "ZT-kis",
                    fontSize: ["20px", "20px", "24px", "24px"],
                  }}
                >
                  Address
                </Heading>

                <Text
                  className="subText"
                  fontSize="md"
                  sx={{
                    fontFamily: "Archivo",
                    fontSize: ["14px", "14px", "16px", "16px"],
                  }}
                >
                  {companyName} | {para1}
                </Text>
                <Text
                  className="subText"
                  fontSize="md"
                  sx={{
                    fontFamily: "Archivo",
                    fontSize: ["14px", "14px", "16px", "16px"],
                  }}
                >
                  {para2}
                </Text>
              </div>

              <AspectRatio ratio={16 / 9}>
                <iframe
                  title="Radach & Family Organics Co., Ltd."
                  src={url}
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </AspectRatio>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </ChakraProvider>
  );
}

export default Contact;