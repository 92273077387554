import "../styles/Landing.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import backgroundImage from "../images/landing.jpg";
import bottleImage from "../images/Bottle_Sparkling.png";
import { ChakraProvider, Image, Heading, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { log, error } from "../utils/log";

const LandingComponent = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  /** for Texts */
  const [landingContent, setLandingContent] = useState("");

  useEffect(() => {
    const fetchLandingContent = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/texts/home/landing`);
        const firstDocument = response.data[0];
        if (firstDocument && firstDocument.field) {
          setLandingContent(firstDocument.field);
        } else {
          error(
            "Landing content not found or does not have content field:",
            firstDocument
          );
        }
      } catch (error) {
        error("Error fetching landing content:", error);
      }
    };

    fetchLandingContent();
  }, []);

  return (
    <ChakraProvider>
     <Flex className="container-fluid background-container">
    {/* Background overlay */}
    <Flex
      className="background-overlay"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        position: "absolute",
        zIndex: 1,
        opacity: 1,
      }}
    ></Flex>

    <Flex
      className="container-fluid landingContent"
      direction="column"
      justify="center"
      align="center"
      textAlign="center"
      px={4}
      mt={{ base: 0, lg: "10%" }}
      zIndex="2"
      overflow="hidden"
    >
      <Flex
        className="row"
        width="100%"
        justify="center"
        align="center"
        overflow="visible"
      >
        {/* Flex for landing content */}
        <Flex
          className="col-xl-6 col-lg-4 col-md-4"
          id="customCol"
          direction="column"
          justify="center"
          align={{ base: "center", md: "flex-start" }}
          mx={{ md: 2 }}
          zIndex={2}
        >
          <motion.h1
            initial={{ y: -200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 2.5, duration: 1 }}
            className="customFont fw-bold mb-4 text-md-start text-center customText"
          >
            Sai Yok Springs Mineral Water
          </motion.h1>

          <motion.h1
            className="customFont text-start customSubText mx-md-0 mx-3 mb-4"
            initial={{ y: -200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 3.5, duration: 1 }}
          >
            {landingContent}
          </motion.h1>
        </Flex>

        {/* Bottle image section */}
        <Flex
          className="col-xl-5 col-lg-6 col-md-6 d-flex justify-content-center mt-5 pt-5 mt-mb-0"
          overflow="visible"
          position="relative"
          zIndex={3}
        >
          {/* Bottle image */}
          <motion.div
            className="image-container"
            initial={{ y: -1500, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1, duration: 1.5 }}
          >
            <Image
              src={bottleImage}
              className="customImage"
              style={{
                width: "auto",
              }}
            />
          </motion.div>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
    </ChakraProvider>
  );
};

export default LandingComponent;